@charset "UTF-8";

@import "variables";

@mixin border-radius-top {
  -moz-border-radius-topleft: $contentBorderRadius;
  -webkit-border-top-left-radius: $contentBorderRadius;
  border-top-left-radius: $contentBorderRadius;
  -moz-border-radius-topright: $contentBorderRadius;
  -webkit-border-top-right-radius: $contentBorderRadius;
  border-top-right-radius: $contentBorderRadius;
}

@mixin border-radius-bottom {
  -moz-border-radius-bottomright: $contentBorderRadius;
  -webkit-border-bottom-right-radius: $contentBorderRadius;
  border-bottom-right-radius: $contentBorderRadius;
  -moz-border-radius-bottomleft: $contentBorderRadius;
  -webkit-border-bottom-left-radius: $contentBorderRadius;
  border-bottom-left-radius: $contentBorderRadius;
}

@mixin border-radius($radius) {
  -moz-border-radius: $radius;
  -webkit-border-radius: $radius;
  border-radius: $radius;
}

@mixin linear-gradient($deg, $color1, $color2) {
  background: $color1;
  background: -moz-linear-gradient($deg, $color1 0%, $color2 100%);
  background: -webkit-linear-gradient($deg, $color1 0%, $color2 100%);
  background: -o-linear-gradient($deg, $color1 0%, $color2 100%);
  background: -ms-linear-gradient($deg, $color1 0%, $color2 100%);
  background: linear-gradient($deg, $color1 0%, $color2 100%);
}

@mixin stdHeading($textColor, $gradientFrom) {
  margin: 0;
  color: $textColor;
  @include linear-gradient(90deg, $gradientFrom, lighten($gradientFrom, 2.3%));
  padding: 5px 20px 5px;
  font-family: $headingFont;
  font-size: $headingFontSize;
  line-height: $headingFontLineHeight;
  @if $headingTextShadow {
    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.5);
  }
  @if type_of($headingFontBold) == 'bool' and $headingFontBold {
    font-weight: bold;
  }
  @if type_of($headingFontBold) == 'number' {
    font-weight: $headingFontBold;
  }
  @if $headingFontItalic {
    font-style: italic;
  }
  @if $headingFontUppercase {
    text-transform: uppercase;
  }
}

@mixin motionParagraphBlockquote() {
  font-size: $motionStdFontSize;
  padding-left: $fixedWidthTextQuotePadding;
  border-left-width: $fixedWidthTextQuoteBorder;
}


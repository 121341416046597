.contentPage {
  margin-left: -1px;
  margin-right: -1px;

  .textHolder {
    > h1, > h2.green, h2.darkgreen {
      margin-left: -20px;
      margin-right: -20px;
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }
}

.contentSettingsToolbar {
  padding: 0;
  .textfield {
    padding-top: 10px;
  }
  input[type=text] {
    max-width: 160px;
  }
  .options {
    padding-top: 5px;
    label {
      font-weight: normal;
    }
  }
}

@charset "UTF-8";

@import "variables";

.motionTextFormattings {
  position: relative;
  margin-left: 0;
  text-rendering: optimizeLegibility;
  font-size: $motionStdFontSize;

  span.underline {
    border-bottom: solid 1px black;
  }

  span.strike {
    text-decoration: line-through;
  }

  span.subscript {
    // @TODO
  }

  span.superscript {
    // @TODO
  }

  h1, .well & h1 {
    background: none;
    color: black;
    text-transform: none;
    font-weight: bold;
    text-shadow: none;
    padding: 0;
    font-family: $bodyFont;
    font-size: 1.5em;
  }

  h2 {
    margin: 0;
    font-size: 1.4em;
  }

  h3 {
    margin: 0;
    font-size: 1.3em;
  }

  h4 {
    margin: 0;
    font-size: 1.2em;
  }

  .amendmentRef {
    font-size: 0.8em;
    opacity: 0.7;
  }

  &.fixedWidthFont {
    font-family: $motionFixedFont;
    //letter-spacing: -0.5px;
    color: $motionFixedFontColor;
  }

  .lineNumber {
    position: relative;
    left: $lineNumberOffset + -1 * $fixedWidthTextStdPadding;
    width: 0;
    display: inline-block;
    float: left; // otherwise problems appear at li > p constucts
    z-index: -1; // Line number highlighting should be behind the text
  }

  .lineNumber:after {
    content: attr(data-line-number);
    color: #C3C3C3;
    font-size: $motionStdFontSize;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
  }

  > ul, > ol {
    padding-left: $fixedWidthTextListPadding;

    .lineNumber {
      left: $lineNumberOffset + -1 * ($fixedWidthTextListPadding + $fixedWidthTextStdPadding);
    }

    ul, ol {
      .lineNumber {
        left: $lineNumberOffset + -1 * (2 * $fixedWidthTextListPadding + $fixedWidthTextStdPadding);
      }

      ul, ol {
        .lineNumber {
          left: $lineNumberOffset + -1 * (3 * $fixedWidthTextListPadding + $fixedWidthTextStdPadding);
        }

        ul, ol {
          .lineNumber {
            left: $lineNumberOffset + -1 * (4 * $fixedWidthTextListPadding + $fixedWidthTextStdPadding);
          }
        }
      }
    }
  }

  .fourdigitsLineNumbers & {
    .lineNumber {
      left: $lineNumberOffset4 + -1 * $fixedWidthTextStdPadding;
    }

    > ul, > ol {
      .lineNumber {
        left: $lineNumberOffset4 + -1 * ($fixedWidthTextListPadding + $fixedWidthTextStdPadding);
      }
    }

    > blockquote {
      .lineNumber {
        left: $lineNumberOffset4 + -1 * ($fixedWidthTextStdPadding + $fixedWidthTextQuotePadding +
            $fixedWidthTextQuoteBorder + $fixedWidthTextQuoteMargin);
      }
    }
  }

  > blockquote {
    @include motionParagraphBlockquote();
    margin-left: $fixedWidthTextQuoteMargin;

    .lineNumber {
      left: $lineNumberOffset + -1 * ($fixedWidthTextStdPadding + $fixedWidthTextQuotePadding +
            $fixedWidthTextQuoteBorder + $fixedWidthTextQuoteMargin);
    }
  }

  .lineNumber.highlighted:before {
    content: '';
    display: block;
    position: absolute;
    left: -8px;
    height: 1.5em;
    margin-top: -1px;
    width: 100vw;
    max-width: $content-max-width;
    z-index: -1;
    background-color: rgba(255, 255, 0, 0);
    transition: background-color 0.5s ease;
  }

  .lineNumber.highlighted-active:before {
    background-color: rgba(255, 255, 0, 1);
  }

  &.smallFont {
    font-size: 12px;

    > blockquote {
      font-size: 12px;
    }
  }
}

.motionTextHolder {
  .well & {
    > h3.green {
      margin-bottom: 22px;
    }

    padding-bottom: 22px;
  }

  .paragraph {
    font-size: 14px;
    clear: both;
    position: relative;

    &.smallFont {
      font-size: 12px;
    }

    .text {
      padding: 15px 10px 15px $fixedWidthTextStdPadding;

      &.collidingAmendment {
        margin-top: -20px;

        > h3 {
          font-size: 1.2em;
          margin-top: 0;
        }
      }
    }

    h4.lineSummary {
      font-size: inherit;
      font-weight: bold;
      margin: 0;
    }
  }

  .textAmendment {
    width: $motionFixedWidth;
    position: relative;

    del, ul.deleted, ol.deleted, blockquote.deleted, pre.deleted {
      color: #ff0000;
      text-decoration: line-through;
    }

    ins, ul.inserted, ol.inserted, blockquote.inserted, pre.inserted {
      text-decoration: underline;
    }

    .preamble {
      position: absolute;
      top: -($inlineAmendmentPreambleHeight - 10);
      height: $inlineAmendmentPreambleHeight;
      color: $inlineAmendmentPreambleColor;
      width: 100%;

      > div {
        position: absolute;
        bottom: 0;
        left: 0;
        max-height: $inlineAmendmentPreambleHeight;
        padding-right: 50px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;

        h3 {
          font-size: 14px;
          display: inline-block;
          margin: 0;
          font-weight: bold;
        }

        .moreAffected {
          font-style: italic;
          font-size: 0.8em;
          margin-top: -3px;
          color: #bbccbb;
        }
      }
    }

    .movedParagraphHint {
      font-style: italic;
      font-size: 0.8em;
      margin-top: 3px;
      color: #bbccbb;
    }
  }

  .tabularData {
    > tbody > tr:first-child {
      > td, > th {
        border-top: none;
      }
    }
  }
}

.bookmarks {
  float: right;
  width: 1px;
  list-style-type: none;
  padding: 0;
  margin-top: 5px;

  > li {
    width: 1px;
    height: 38px;
    position: relative;
    margin-bottom: 4px;

    > a {
      display: block;
      position: absolute;
      white-space: nowrap;
      padding: 10px;
      top: 0;
      left: 2px;
      color: $bookmarkAmendmentText;
      min-width: 40px;
      -moz-border-radius-topright: 10px;
      -webkit-border-top-right-radius: 10px;
      border-top-right-radius: 10px;
      -moz-border-radius-bottomright: 10px;
      -webkit-border-bottom-right-radius: 10px;
      border-bottom-right-radius: 10px;
      font-weight: bold;
    }

    &.comment > a {
      @include linear-gradient(90deg, $bookmarkCommentColor, lighten($bookmarkCommentColor, 3.5%));

      &.zero {
        opacity: 0.5;
      }

      .count:after {
        content: attr(data-count);
        padding-left: 4px;
      }
    }

    &.amendment > a {
      @include linear-gradient(90deg, $bookmarkAmendmentBackground, lighten($bookmarkAmendmentBackground, 3.5%));
    }

    .hider {
      @include linear-gradient(90deg, rgb(180, 180, 180), rgb(220, 220, 220));
    }
  }
}

.commentScreeningQueue {
  margin-left: 50px;
  color: darkgray;
}

.motionComment {
  border: solid 1px $commentBorder;
  background: $commentBackground;
  border-radius: 3px;
  margin: 15px 20px 30px;

  &.replyComment {
    margin-top: -15px;
  }

  .commentHeader {
    background: none;
    color: $colorLinksLight;
    font-family: $bodyFont;
    font-size: 1em;
    font-weight: bold;
    line-height: 18px;
    margin: 0;
    text-transform: none;
    padding: $commentPadding;
  }

  .commentName {
    @media screen and (min-width: 800px) {
      float: right;
      padding-top: $commentPadding;
      padding-right: $commentPadding;
    }
  }

  .commentWriteHeader {
    border-bottom: solid 1px $commentBackground;
    font-size: 1.2em;
  }

  .commentText {
    padding: 0 $commentPadding $commentPadding $commentPadding;
  }

  .commentFullTextarea {
    padding: 0 $commentPadding $commentPadding $commentPadding;
  }

  .date {
    color: #757676;
    float: right;
    padding: $commentPadding;
    margin-left: 20px;
  }

  .commentBottom {
    height: 23px;
    position: relative;

    .entry {
      position: absolute;
      bottom: -1px;
      height: 24px;
      font-size: 12px;
      border-top: solid 1px $commentBorder;
      text-align: center;
      font-weight: normal;
    }

    .link {
      left: -1px;
      padding: 2px;
      width: 24px;
      border-right: solid 1px $commentBorder;
      border-top-right-radius: 3px;
    }

    .replyButton {
      right: -1px;
      padding: 2px 5px;
      border-left: solid 1px $commentBorder;
      border-top-left-radius: 3px;
    }

    .delLink {
      color: red;
      left: 3px;
      width: 24px;
      border-right: solid 1px $commentBorder;
      border-top-right-radius: 3px;
    }

    .delLink + .link {
      border-top-right-radius: 0;
    }
  }

  .delLink {
    float: right;
    margin-left: 20px;

    a:link, a:visited {
      color: #ccc;

      &:hover, &:focus {
        color: #bbb;
      }
    }
  }

  .screening {
    > * {
      display: inline-block;
      width: 49%;
      text-align: center;
    }
  }

  > label {
    display: block;
    text-align: center;
  }

  &.form-horizontal .form-group {
    margin-right: $commentPadding;
    margin-left: $commentPadding;

    > * {
      padding-right: 0;
    }
  }

  .commentNotifications {
    padding: $commentPadding;
    overflow: visible;

    label {
      font-weight: normal;
      font-size: 13px;
    }

    .btn {
      font-weight: normal;
    }

    .selectlist {
      float: right;
    }

    .dropdown-menu {
      font-size: 12px;
    }
  }

  .submitrow {
    padding: $commentPadding;
    text-align: center;
  }
}

.motionCommentReplies {
  @media screen and (min-width: 1000px) {
    padding-left: 100px;
  }

  .motionComment {
    margin-top: -20px;
  }
}

.withdrawForm {
  text-align: center;

  .ask {
    font-size: 16px;
    margin-bottom: 15px;
    margin-top: 15px;
  }
}

.motionSupportFinishForm, .amendmentSupportFinishForm {
  text-align: center;
  margin-bottom: 20px;
}

.sectionType0 + .motionTextHolder {
  // Don't let amendment markers be hidden behind the next section
  clear: both;
}

.sectionType3 { // Images
  padding: 10px;

  img {
    max-height: 200px;
    max-width: 100%;
  }
}

.motionRightCol {
  padding-top: 0;
  font-size: 14px;

  .motionTextFormattings {
    font-size: 14px;
  }

  > section {
    padding: 0 30px 20px 0;
  }
}

iframe.pdfViewer {
  width: 100%;
  height: 600px;
  border: none;
}

.sectionType5 { // PDFs
  margin-left: -1px;
  margin-right: -1px;
}

.gotoLineNumerPanel {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  right: 0;
  z-index: 10;
  background: white;
  border-top: solid 1px #ccc;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.4);
  display: none;
  padding: 10px;

  &.active {
    display: block;
  }

  .lineNumberNotFound {
    color: red;
  }

  .input-group {
    max-width: 300px;
  }

  .form-group {
    margin-bottom: 0;
  }
}

.share_buttons {
  &:after,
  &:before {
    content: " ";
    display: table;
  }

  &:after {
    clear: both;
  }

  ul {
    padding: 0;
    margin: 0;
  }

  li {
    height: 30px;
    display: inline-block;
    margin-right: 14px;
    overflow: hidden;
    width: 103px;

    &:last-child {
      margin-right: 0;
    }

    a {
      font-family: Arial, Helvetica, sans-serif;
      color: #fff;
      position: relative;
      display: block;
      padding-left: 10px;
      padding-right: 5px;
      font-size: 12px;
      line-height: 31px;
      text-decoration: none;
      border: 1px solid #ddd;
      background-color: #fff;

      &:hover {
        background-color: #eee;
      }
    }

    &.facebook {
      a {
        color: #247bbd;
        padding-left: 5px;

        .icon {
          font-size: 17px;
        }
      }
    }

    &.twitter {
      a {
        color: #247bbd;

        .icon {
          font-size: 20px;
        }
      }
    }
  }
}

#amendmentMergeForm {
  #motionTitlePrefix, .dropdown-toggle {
    max-width: 230px;
  }

  .checkButtonRow {
    text-align: center;
    margin: 20px 0;
  }

  .otherAmendmentStatus {
    .row {
      margin-bottom: 15px;
    }

    .by {
      display: block;
      font-size: 0.8em;
    }

    .amendmentAjaxTooltip {
      float: right;
      color: gray;
      margin-right: -15px;
      margin-top: 7px;
    }
  }

  .affectedParagraphs {
    .paragraph.unmodified .affectedBlock {
      display: none;
    }

    .paragraph.modified .unmodifiedVersion {
      display: none;
    }
  }

  .affectedBlock {
    margin-top: 20px;

    > textarea {
      display: none;
    }
  }

  .modifySelector {
    margin-top: 15px;

    label {
      margin-right: 20px;
      font-weight: normal;
    }
  }

  .save-row {
    text-align: center;
  }

  .saveholder {
    .checkAmendmentCollisions {
      display: none;
    }
  }
}

.amendmentCollisionsHolder {
  .amendmentBy {
    color: gray;
  }

  .amendmentOverrideBlock {
    background-color: #f5f5f5;
    margin: 15px -10px;
    padding: 10px;
    border: solid 1px #ddd;
    border-radius: 4px;

    > h3 {
      font-size: 18px;
      margin-top: 0;
    }

    > textarea {
      display: none;
    }
  }
}

.motionData {
  .privateNotes {
    th {
      padding-top: 25px;
    }

    td {
      padding-top: 20px;
    }

    blockquote {
      margin-left: 0;
      cursor: pointer;
      font-style: italic;
      color: #666;
    }

    textarea {
      line-height: 1.1;
      height: 50px;
      width: 100%;
      @media screen and (min-width: 800px) {
        width: calc(100% - 110px);
      }
    }

    .btn {
      margin-top: 2px;
      margin-left: 5px;
    }

    .btnEdit {
      margin-left: -30px;
      margin-top: -1px;
      float: left;
    }
  }
}

.privateNoteOpener {
  margin-top: -20px;
  margin-bottom: 10px;
  padding-left: 10px;
  width: 30%;

  .btn {
    font-weight: normal;
  }

  & + .proposedChangesOpener .btn {
    margin-top: -30px;
  }
}


.privateParagraphNoteHolder {
  font-family: $bodyFont;

  .privateParagraphNoteOpener {
    position: absolute;
    bottom: 0;
    left: 40px;
    opacity: 0.5;

    .btn {
      font-weight: normal;
    }
  }

  label {
    width: 100%;
    @media screen and (min-width: 800px) {
      width: calc(100% - 110px);
    }
  }
  textarea.form-control {
    line-height: 1.1;
    height: 50px;
    width: 100%;
  }

  .btn {
    margin-top: 2px;
    margin-left: 0;
  }

  .btnEdit {
    margin-left: -30px;
    margin-top: -1px;
    float: left;
  }

  blockquote {
    color: #666;
    font-style: italic;
    cursor: pointer;
  }
}

ul + .privateParagraphNoteHolder, ol + .privateParagraphNoteHolder {
  .privateParagraphNoteOpener {
    left: 85px;
  }
  blockquote {
    margin-left: 45px;
  }
  form {
    margin-left: 40px;
  }
}

blockquote + .privateParagraphNoteHolder {
  .privateParagraphNoteOpener {
    left: 100px;
  }
  blockquote {
    margin-left: 60px;
  }
  form {
    margin-left: 55px;
  }
}


.motionChangeView {
  .notDisplayable {
    color: red;
  }

  .noChanges {
    color: gray;
  }

  .motionDataTable {
    margin-bottom: 25px;
  }
}

.motionSupportForm {
  .supportQuestion {
    margin-top: 10px;
  }

  .supportBlock {
    display: flex;
    flex-direction: row;

    .colName, .colGender, .colOrga {
      flex: 1;
      padding: 0 10px;
    }

    .colSubmit {
      flex: 0;
    }

    > * {
      padding: 0 10px;

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }
    }
  }

  .loggedOutWarning {
    font-size: 0.8em;
    margin-top: 6px;
    padding: 0 15px;
  }
}
